.myPetitions {
    .myPetitions__options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 7.6rem;
        margin-bottom: 3.25rem;
        @media screen and (max-width: 425px) {
            margin-top: 4.4rem;
        }
    }

    .myPetitions__links {
        display: flex;
        gap: 2.875rem;

        .myPetitions__navLink {
            text-decoration: none;
            color: #979797;
            font-weight: 400;
            font-size: 1.25rem;

            span {
                background-color: #E0EDF8;
                color: #175CD3;
                border-radius: 50%;
                padding: 0 5px;
                font-size: 0.875rem;
            }
        }
    }

    .myPetitions__verify-btn {
        color: #14BA6D;
        background-color: #fff;
        font-size: 1rem;
        font-weight: 800;
        border: 1px solid #14BA6D;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;
        @media screen and (max-width: 647px) {
            margin-top: 2rem;
        }
    }

    .myPetitions__empty {
        width: 50vw;
        border: 1px solid #C5C6C5;
        height: 9.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666666;
        font-size: 18px;
        margin: 0 auto;
        border-radius: 10px;
        @media screen and (max-width:768px) {
            width: 80vw;
        }
    }

    .create__petition-btn {
        cursor: pointer;
        background-color: #3695D8;
        border: none;
        color: #fff;
        font-weight: 600;
        border-radius: 13px;
        height: 3.4rem;
        display: flex;
        gap: 2rem;
        align-items: center;
        padding: 0.8rem 3.25rem;
        margin: 2.4rem auto;
    }

    .profile-logout-btn {
        margin-bottom: 2.9rem;
        color: #E64452;
        border: 1px solid #E64452;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        display: flex;
        margin-left: auto;
        cursor: pointer;
    }
}

// .active{
//     font-weight: 500;
//     color: #0B1F33;
//     text-decoration: underline;
// }
.pagination-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.petition-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.petition-modal__content {
    // position: absolute;
    // top: 20rem;
    // left: 28rem;
    box-shadow: 0px 4px 8px 0px #10184014;
    border-radius: 8px;
    background-color: #fff;
    padding: 1rem 1.25rem;

    .petition-modal-top {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        color: #282A2F;
        margin-bottom: 1rem;

        img {
            cursor: pointer;
        }
    }

    .petition-modal__middle {
        font-size: 12px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    hr {
        margin-bottom: 1rem;
    }

    .petition-modal__bottom {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        .petition-modal__cancel {
            background-color: #fff;
            border: 1px solid #B7B7B7;
            box-shadow: 0px 1px 2px 0px #266EFF0D;
            font-size: 12px;
            color: #000000;
            padding: 0.5rem 1rem;
            border-radius: 3px;
            cursor: pointer;
        }

        .petition-modal__sign-in {
            background-color: #3695D8;
            color: #fff;
            box-shadow: 0px 1px 2px 0px #266EFF0D;
            border-radius: 3px;
            border: none;
            padding: 0.5rem 1rem;
            cursor: pointer;
        }
    }
}

.pagination-block {
    display: flex;
    justify-content: center;
    margin-top: 4.3rem;
}

.logout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .logout-modal__content {
        box-shadow: 0px 4px 8px 0px #10184014;
        border-radius: 8px;
        background-color: #fff;
        padding: 1rem 1.25rem;

        .logout-modal__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            color: #101828;

            img{
                object-fit: cover;
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
            }
        }
        .logout-modal__middle{
            font-size: 14px;
            font-weight: 400;
            color: #475467;
            margin-bottom: 2rem;
        }
        .logout-modal__bottom{
            display: flex;
            justify-content: space-between;

            .logout-modal__cancel{
                width: 9rem;
                height: 2.5rem;
                background-color: #fff;
                color: #344054;
                font-weight: 600;
                font-size: 1rem;
                border: 1px solid 
                #D0D5DD;
                border-radius: 8px;
                cursor: pointer;
            }
            .logout-modal__btn{
                width: 9rem;
                height: 2.5rem;
                background-color:#D92D20;
                color: #fff;
                font-weight: 600;
                font-size: 1rem;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}