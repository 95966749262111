.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 22.5rem;
    padding-top: 6rem;
    align-items: center;
    padding-bottom: 18rem;

    @media screen and (max-width:425px) {
        padding-top: 3rem;
        padding-bottom: 7rem;
        width: 20rem;
    }

    .login__img {
        width: 3rem;
        height: 3rem;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .login__title {
        color: #101828;
        font-size: 1.5rem;
        line-height: 2.3rem;
        font-weight: 700;
        margin: 0;
        padding: 0;
    }

    .login__text {
        color: #667085;
        font-size: 1.125rem;
        margin-bottom: 2rem;
    }

    .login__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .login__label {
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 6px;
            color: #344054;
            align-self: flex-start;
            @media screen and (max-width:425px) {
                margin-left: 1rem;
            }
        }
        
        .loginInput {
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            margin-bottom: 0.5rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #101828;
            // padding: 0.625rem 0.875rem;
            width: 22.5rem;
            height: 2.75rem;
            padding-left: 0.8rem;
            font-weight: 400;
            @media screen and (max-width:425px) {
                width: 20rem;
            }
            @media screen and (max-width:350px) {
                width: 18.5rem;
            }

            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            }
        }
        .loginErrorInput{
            border: 2px solid #F04438;
            border-radius: 8px;
            margin-bottom: 0.5rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #101828;
            font-weight: 400;
            // padding: 0.625rem 0.875rem;
            width: 22.5rem;
            height: 2.75rem;
            padding-left: 0.8rem;

            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            }
        }
        .login__errorBlock{
            display: flex;
            justify-content: space-between;
            width: 22.5rem;
            .login__error-text{
                color: #F04438;
                font-size: 14px 
            }
            .login__error-img{
                width: 14px;
                height: 14px; 
                object-fit: cover 
            }
        }
        .login__forget {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media screen and (max-width:425px) {
               margin-right: 1rem;
            }
            
            .login__text {
                color: #0077B6;
                font-size: 1rem;
                font-weight: 500;
                padding-top: 0.6rem;
                cursor: pointer;
            }
        }
        .loginBtn{
            cursor: pointer;
            background-color: #489FE1;
            color: #fff;
            border-radius: 8px;
            font-size: 1rem;
            font-weight: 600;
            border: none;
            padding: 0.625rem 0;
            margin-bottom: 1rem;
            line-height: 1.5rem;
            width: 22.5rem;
            transition: 0.3s ease-in;
            @media screen and (max-width:425px) {
                width: 20rem;
            }
            @media screen and (max-width:350px) {
                width: 18.5rem;
            }

            &:hover{
                background-color: #0e6fb9;
            }
        }
        .googleBtn{
            cursor: pointer;
            background-color: #fff;
            color: #344054;
            border-radius: 8px;
            font-size: 1rem;
            font-weight: 600;
            border: 1px solid #D0D5DD;
            padding: 0.625rem 0;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            justify-content: center;
            margin-bottom: 2rem;
            width: 22.5rem;
            @media screen and (max-width:425px) {
                width: 20rem;
            }
            @media screen and (max-width:350px) {
                width: 18.5rem;
            }

            img{
                width: 1.5rem;
                height: 1.5rem;
            }
        }

            .link{
                color: #0077B6;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.25rem;
                text-decoration: none;
            }
    }
}
.loginPasswordBlock{
    position: relative;
    .showEye{
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }
}

