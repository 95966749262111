.petitions-list{
    background-color: #FAFCFF;
    padding-bottom: 10.5rem;
    .petitions-block{
        padding-top: 2.5rem;   
    }
    .pagination-block{
        display: flex;
        justify-content: center;
        margin-top: 4.3rem;
    }
    .petitions__buttons{
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
            button{
                background-color: #E3EBFC;
                border-radius: 24px;
                padding: 9px 1.375rem;
                border: none;
                color: #0B1F33;
                font-size: 13px;
                font-weight: 400;
                line-height: 22px;
                cursor: pointer;

                &:hover{
                    background-color: #2039B8;
                    color: #fff;
                    transition: 0.5s ease-in;
                }
            }
    }
}
