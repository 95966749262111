.register-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;

    .register__error-title {
        font-size: 2rem;
    }

    .register__error-btn {
        cursor: pointer;
        background-color: #489FE1;
        color: #fff;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 600;
        border: none;
        padding: 0.625rem;
        margin-bottom: 1rem;
        line-height: 1.5rem;
    }
}

.register {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding-top: 4.3rem;
    padding-bottom: 21rem;

    @media screen and (max-width:425px) {
        padding-top: 3rem;
        padding-bottom: 7rem;
        width: 19rem;
    }

    .register__icon {
        width: 3rem;
        height: 3rem;
        margin-bottom: 1.5rem;
    }

    .register__title {
        font-size: 1.875rem;
        margin: 0;
        padding: 0;
    }

    .register__text {
        color: #667085;
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    form {
        display: flex;
        flex-direction: column;

        label {
            font-size: 0.875rem;
            font-weight: 600;
            margin-bottom: 6px;
            color: #344054;
        }

        .register__input {
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            margin-bottom: 0.5rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #101828;
            padding: 0.625rem 0.875rem;
            width: 22.5rem;

            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            }
            @media screen and (max-width:425px) {
                width: 20rem;
            }
            @media screen and (max-width:350px) {
                width: 17.5rem;
            }

        }
        .register__input-err{
            border-radius: 8px;
            border: 1px solid #F04438;
            margin-bottom: 0.5rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #101828;
            padding: 0.625rem 0.875rem;
            width: 22.5rem;

            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            }
        }
        .register__input-password{
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            margin-bottom: 0.5rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #101828;
            padding: 0.625rem 0.875rem;
            width: 22.5rem;

            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            } 
            @media screen and (max-width:425px) {
                width: 20rem;
            }
            @media screen and (max-width:350px) {
                width: 17.5rem;
            }
        }
        .register__input-passwordErr{
            border-radius: 8px;
            border: 1px solid #F04438;
            margin-bottom: 0.5rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #101828;
            font-weight: 400;
            padding: 0.625rem 0.875rem;
            width: 22.5rem;
            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            }
        }

        p {
            color: #667085;
            font-size: 0.875rem;
        }

        .createBtn {
            cursor: pointer;
            background-color: #489FE1;
            color: #fff;
            border-radius: 8px;
            font-size: 1rem;
            font-weight: 600;
            border: none;
            padding: 0.625rem 0;
            margin-bottom: 1rem;
            margin-top: 3rem;
            line-height: 1.5rem;
            transition: 0.3s ease-in;

            &:hover{
                background-color: #0e6fb9;
            }
        }

        .googleBtn {
            cursor: pointer;
            background-color: #fff;
            color: #344054;
            border-radius: 8px;
            font-size: 1rem;
            font-weight: 600;
            border: 1px solid #D0D5DD;
            padding: 0.625rem 0;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            justify-content: center;
            margin-bottom: 2rem;
        }

        .text {
            text-align: center;
            color: #667085;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;

            .link {
                color: #0077B6;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.25rem;
                text-decoration: none;
            }
        }

        .register__errorBlock{
            display: flex;
            justify-content: space-between;
            width: 22.5rem;
            .register__error-text{
                color: #F04438;
                font-size: 14px 
            }
            .register__error-img{
                width: 14px;
                height: 14px; 
                object-fit: cover 
            }
        }
    }
}

.registerPasswordBlock {
    position: relative;

    .registerShowEye {
        position: absolute;
        top: 8px;
        right: 12px;
        cursor: pointer;
    }
}

.register__info{
    margin-top: 0;
}