.petition {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #E3EBFC;
    margin-bottom: 1.5rem;
    padding: 1.5rem;

    // height: 20.5rem;

    .petition__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 85vw;
        @media screen and (max-width: 425px) {
            flex-wrap: wrap;
            
        }
    }

    &:hover {
        box-shadow: 0px 8px 20px 0px #00000040;
    }

    .petition__left {
        width: 60vw;
        padding-bottom: 2rem;

        @media screen and (max-width: 800px) {
            width: 50vw;
        }
        @media screen and (max-width: 425px) {
            width: 85vw;
        }

        .petition__tags {
            display: flex;
            justify-content: flex-start;
            gap: 0.5rem;

            .petition__tag {
                background-color: #DBE5FC;
                color: #0B1F33;
                font-size: 14px;
                font-weight: 600;
                padding: 2px 10px;
                border-radius: 16px;
            }
        }

        .petition__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;
            font-size: 18px;
            font-weight: 600;
            width: 100%;

            .petition__title {
                font-size: 1.18rem;
                margin: 0;
                padding: 0;
            }

            .petition__published {
                color: #14BA6D;
            }

            .petition__moderation {
                color: #FFCB14;
            }

            .petition__rejected {
                color: #E64452;
            }
        }

        .progress-bar-container {
            // width: 53rem;
            width: 100%;
            height: 1.5rem;
            background-color: #F2F4F7;

            @media screen and (max-width: 800px) {
                display: none;
            }

            .progress-bar {
                height: 100%;
                width: 0;
                background-color: #007bff;
                transition: width 0.3s ease;
            }

            .progress-bar-fill {
                height: 100%;
            }
        }

        p {
            color: #9EA7AF;
            font-size: 1rem;

        }

        .petition__progress {
            width: 100%;
        }

        .count {
            color: #0B1F33;

        }
    }

    .petition__right {
        width: 20vw;
        height: 11.3rem;

        @media screen and (max-width: 800px) {
            width: 30vw;
        }
        @media screen and (max-width: 425px) {
            width: 85vw;
            height: 10.7rem;
        }

        .petition__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
        }
    }
}

.progress-bar__second {
    width: 100%;
    height: 1.5rem;
    background-color: #F2F4F7;
    display: none;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 800px) {
        display: block;
    }

    .progress-bar {
        height: 100%;
        width: 0;
        background-color: #007bff;
        transition: width 0.3s ease;
    }

    .progress-bar-fill {
        height: 100%;
    }
}