.petitionSearch{
    background-color: #FAFCFF;
    .petitionSearch__title{
        padding-top: 6.25rem;
        padding-bottom: 2.4rem;
        margin: 0;
        @media screen and (max-width: 768px) {
            padding-top: 5rem;
        }
        @media screen and (max-width: 425px) {
            padding-top: 4rem;
        }
    }
    .petitionSearch__inputBox{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 2rem;

        .petitionSearch__inp{
            width: 76vw;
            background: url('../../ui/images/search.svg') no-repeat;
            background-position: left center;
            background-position-x: 1.625rem;
            padding-top: 1.125rem;
            padding-bottom: 0.875rem;
            padding-left: 4.6rem;
            border-radius: 6px;
            border: 1px solid #0B1F33;
            font-size: 18px;
            &::placeholder{
                color: #C5C6C5;
                font-size: 1rem;
                font-weight: 400;
            }
            @media screen and (max-width: 425px) {
                background-position-x: 0.5rem;
                width: 55vw;
                padding-left: 3rem;
            }
        }
        .petitionSearch__clearIcon{
            position: absolute;
            top: 1.1rem;
            right: 12rem;
            cursor: pointer;
        }
        .petitionSearch__btn{
            width: 11rem;
            height: 3.5rem;
            background-color: #489FE1;
            border-radius: 6px;
            color: #fff;
            border: none;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            @media screen and (max-width: 425px) {
                width: 6.3rem;
            }
        }
    }
}