.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 99%;
    background-color: #333;
    color: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    z-index: 1000;
}

.cookie-banner p {
    margin: 0;
    flex: 1;
}

.cookie-banner a {
    color: #00bfff;
    text-decoration: underline;
    margin-left: 5px;
}

.cookie-banner a:hover {
    text-decoration: none;
}

.cookie-banner button {
    background-color: #00bfff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 15px;
}

.cookie-banner button:hover {
    background-color: #0080ff;
}