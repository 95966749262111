.petition-details {
    padding-top: 6.9rem;
    background-color: #FAFCFF;

    @media screen and (max-width: 425px) {
        padding-top: 4.4rem;
    }

    .myPetition__title {
        text-align: center;
        margin: 0;
        padding-bottom: 2rem;
        font-size: 1.5rem;
        width: 90vw;
    }

    .petition__published {
        color: #14BA6D;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    .petition__moderation {
        color: #FFCB14;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2rem;

    }

    .petition__rejected {
        color: #E64452;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 2rem;

    }

    .petition-details__images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .petition-details__petition-img {
            width: 60vw;
            height: 32rem;
            object-fit: cover;
            border-radius: 15px;
            margin-bottom: 2.8rem;

            @media screen and (max-width: 800px) {
                width: 95vw;
            }
            @media screen and (max-width: 425px) {
                height: 16.5rem;
            }
        }

        .petition-details__votes {
            width: 21rem;
            //height: 15rem;
            padding: 1.25rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            box-shadow: 0px 1px 4px 0px #E3EBFC;
            border-radius: 15px;
            @media screen and (max-width: 400px) {
                width: 19rem;
            }
            @media screen and (max-width: 320px) {
                width: 16.5rem;
            }
            .votes__progressBar-container {
                width: 21.5rem;
                height: 1.5rem;
                background-color: #F2F4F7;
                border-radius: 4px;
                @media screen and (max-width: 400px) {
                    width: 19rem;
                }
                @media screen and (max-width: 320px) {
                    width: 16.5rem;
                }

                .votes__progressBar {
                    height: 100%;
                    background-color: #007bff;
                    transition: width 0.3s ease;
                }

                .votes__progress-bar-fill {
                    height: 100%;

                }
            }

            .votes__count {
                display: flex;
                justify-content: space-between;

                .votes__res {
                    color: #3695D8;
                    font-weight: 700;
                    font-size: 1.5rem
                }

                .votes__total {
                    font-weight: 700;
                    font-size: 1.5rem
                }
            }

            .votes__author {
                font-weight: 700;
                font-size: 1rem;
                display: flex;
                align-items: center;
            }

            .votes__btns {
                margin-top: 2.375rem;

                .votes__btns-block {
                    display: flex;
                    gap: 3.875rem;
                    align-items: center;
                    @media screen and (max-width: 425px) {
                        gap: 2.5rem;
                    }
                }

                .votes__btn-for {
                    background-color: #3695D8;
                    width: 11.75rem;
                    height: 3.5rem;
                    border-radius: 15px;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                .voted {
                    background-color: #9EA7AF;
                    width: 11.75rem;
                    height: 3.5rem;
                    border-radius: 15px;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                .votes__btn-against {
                    background-color: #FF5A3F;
                    width: 11.75rem;
                    height: 3.5rem;
                    border-radius: 15px;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                .voted {
                    background-color: #9EA7AF;
                }

                .votes__quantity {
                    color: #7D8D93;
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            .votes__cancelBtn {
                background-color: #FFCB14;
                width: 11.75rem;
                height: 3.5rem;
                border-radius: 15px;
                border: none;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                margin-top: 2rem;
                cursor: pointer;
            }
        }
    }

    .petition-details__info {
        color: #666666;
        margin-bottom: 3.5rem;

        .petition-details__calendar {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .petition-details__petition {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 1px 4px 0px #E3EBFC;
        padding: 2rem;
        margin-bottom: 6.6rem;
    }

    .petition-details__buttons {
        display: flex;
        gap: 1rem;
        padding-bottom: 11.3rem;
        @media screen and (max-width: 425px) {
            padding-bottom: 9rem;
        }

        .buttons__edit {
            color: #3695D8;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 2.9rem;
            background-color: #fff;
            font-weight: 600;
            font-size: 1.125rem;
            cursor: pointer;
        }

        .buttons__publish {
            background-color: #3695D8;
            color: #fff;
            font-weight: 600;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 0.7rem;
            font-size: 1.125rem;
            cursor: pointer;
        }

        .buttons__delete {
            background-color: #E64452;
            color: #fff;
            border: none;
            border-radius: 8px;
            width: 12rem;
            height: 2.8rem;
            font-size: 18px;
            cursor: pointer;
            @media screen and (max-width: 425px) {
                margin: 0 auto;
            }
        }
    }
}

.petition-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    .petition-alert__content{
        box-shadow: 0px 4px 8px 0px #10184014;
        border-radius: 8px;
        background-color: #fff;
        padding: 1rem 1.25rem;
        width: 25rem;
        height: 16.25rem;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        @media screen and (max-width: 460px) {
            width: 20rem;
            height: 18rem;
        }
        @media screen and (max-width: 390px) {
            width: 15rem;
            height: 20rem;
        }

        .petition-alert__top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }
        .petition-alert__middle{
            margin-bottom: 2rem;
            .petition-alert__title{
                font-size: 18px;
                font-weight: 600;
                margin: 0;
            }
            .petition-alert__info{
                font-size: 14px;
                color:#475467;
            }
        }
        .petition-alert__bottom{
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 460px) {
                flex-wrap: wrap;
                gap: 1rem;
                justify-content: center;
            }
            
            .petition-alert__cancel{
                background-color: #fff;
                border: 1px solid #D0D5DD;
                border-radius: 8px;
                width: 10.6rem;
                height: 2.75rem;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;
                color:#344054;
            }
            .petition-alert__delete{
                background-color: #D92D20;
                border: none;
                color: #fff;
                border-radius: 8px;
                width: 10.6rem;
                height: 2.75rem;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}