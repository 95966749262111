.forgot {
    .forgot__content {
        display: flex;
        flex-direction: column;
        width: 30vw;
        margin: 0 auto;
        padding-top: 6rem;
        padding-bottom: 23rem;
        align-items: center;

        @media screen and (max-width:768px) {
            padding-top: 6.6rem;
            padding-bottom: 11rem;
            width: 22.5rem;
        }
        @media screen and (max-width:425px) {
            width: 21rem;
        }
        @media screen and (max-width:355px) {
            width: 18rem;  
        }
        .forgot__img{
            width: 3rem;
            height: 3rem;
            margin: 0 auto;
        }
        .forgot__title{
            font-size: 2rem;
            line-height: 2.3rem;
            color: #101828;
            font-weight: 500;
            margin-bottom: 0;
        }
        .forgot__info{
            color:#667085;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 2rem;
        }
        .forgot__code{
            color: #344054;
            font-size: 1rem;
            align-self: flex-start;
            font-weight: 500;
            margin-bottom: 5px;
        }
        .forgot__inputs{
            display: flex;
            gap: 0.5rem;
            @media screen and (max-width:425px) {
               gap: 5px;
            }
        }
        .forgot__input{
            align-self: flex-start;
            height: 4rem;
            width: 3.4rem;
            border: 1px solid #D0D5DD;
            border-radius: 8px;
            font-size: 3rem;
            text-align: center;
            color: #489FE1;
            @media screen and (max-width:425px) {
                width: 3rem;
                height: 3.5rem;
            }
            @media screen and (max-width:355px) {
                width: 2.7rem;
            }
            @media screen and (max-width:330px) {
                width: 2.5rem;
            }
        }
        .forgot__btn, .forgot__resendBtnTrue{
            cursor: pointer;
            background-color: #489FE1;
            color: #fff;
            border: none;
            margin-bottom: 2rem;
            width: 30vw;
            height: 2.75rem;
            border-radius: 8px;
            font-weight: 700;
            font-size: 1rem;
            margin-top: 2rem;
            @media screen and (max-width:768px) {
                width: 22.5rem;
            }
            @media screen and (max-width:425px) {
                width: 21rem;
            }
            @media screen and (max-width:355px) {
                width: 18rem;  
            }
        }
        .forgot__btnDisabled, .forgot__resendBtnFalse{
            cursor: pointer;
            background-color: #9EA7AF;
            color: #fff;
            border: none;
            margin-bottom: 2rem;
            width: 30vw;
            height: 2.75rem;
            border-radius: 8px;
            font-weight: 700;
            font-size: 1rem;
            margin-top: 2rem;
            @media screen and (max-width:768px) {
                width: 22.5rem;
            }
            @media screen and (max-width:425px) {
                width: 21rem;
            }
            @media screen and (max-width:355px) {
                width: 18rem;  
            }
        }
        .forgot__text{
            margin-bottom: 2rem;
        }
        .forgot__timer{
            color: #3695D8;
        }
    }
    .forgot__success{
        color: #3695D8;
        font-size: 14px;
        font-weight: 600;
        align-self: flex-start;
    }
    .forgot__err{
        color: #F04438;
        font-size: 14px;
        font-weight: 600;
        align-self: flex-start;
    }
}
