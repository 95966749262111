.petitions__filterBlock{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    .petitions__buttons{
        display: flex;
        gap: 1rem;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        @media screen and (max-width: 425px) {
           gap: 0.5rem;
        }
            .filter-button{
                background-color: #E3EBFC;
                border-radius: 24px;
                padding: 9px 1.375rem;
                border: none;
                color: #0B1F33;
                font-size: 13px;
                font-weight: 400;
                line-height: 22px;
                cursor: pointer;
    
                &:hover{
                    background-color: #2039B8;
                    color: #fff;
                    transition: 0.5s ease-in;
                }
            }
            .clickedBtn{
                background-color: #2039B8;
                border-radius: 24px;
                padding: 9px 1.375rem;
                border: none;
                color: #fff;
                font-size: 13px;
                font-weight: 400;
                line-height: 22px;
                cursor: pointer;
            }
    }
}
