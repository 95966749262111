.verifyPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .verifyPage__title {
     
    }
    
    .verificationCamera__btn {
        margin-top: 4.8rem;
        width: 14.3rem;
        background-color: #3998E8;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 800;
        cursor: pointer;
        transition: 0.3s ease-in;
        margin-bottom: 2rem;

        &:hover {
            background-color: #2478bd;
        }
    }
    .verificationCamera__btn-cancel{
        width: 14.3rem;
        background-color: #FF5A3F;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 800;
        cursor: pointer;
        transition: 0.3s ease-in;
        margin-bottom: 2rem;

        &:hover {
            background-color: #f37764;
        }
    }
    .verificationCamera__ImgContainer{
        display: flex;
        flex-direction: column;
        .verificationCamera__Img{
            width: 20rem;
            height: 15rem;
        }
    }
    .redText{
        color: #FF5A3F;
    }
    .greenText{
        color: green;
    }
}
.verificationCamera {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding-bottom: 5rem;
    .verificationCamera__btn {
        margin-top: 4.8rem;
        width: 14.3rem;
        background-color: #3998E8;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 800;
        cursor: pointer;
        transition: 0.3s ease-in;
        margin-bottom: 2rem;

        &:hover {
            background-color: #2478bd;
        }
    }
    .verificationCamera__btn-cancel{
        width: 14.3rem;
        background-color: #FF5A3F;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 800;
        cursor: pointer;
        transition: 0.3s ease-in;
        margin-bottom: 2rem;

        &:hover {
            background-color: #f37764;
        }
    }
    .verificationCamera__ImgContainer{
        display: flex;
        flex-direction: column;
        .verificationCamera__Img{
            width: 20rem;
            height: 15rem;
        }
    }
}