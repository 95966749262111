.dropdown {
    margin-bottom: 2rem;
    .dropdown-btn {
        padding: 1rem;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        font-weight: 500;
        color: #32303A;
        width: 9.25rem;
        background: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 0.5rem;
        }
    }
    .dropdown-content {
        border: 1px solid #F2F4F7;
        width: 20rem;
        border-radius: 8px;
        .dropdown-item {
            padding: 10px 14px;
            font-weight: 600;
            background-color: #fff;
            cursor: pointer;
            transition: 0.2s ease;
            display: flex;
            justify-content: space-between;

            &:hover{
                background-color: #F9FAFB;

                &::after{
                    content: url('../../../ui/images/check.svg');
                }
            }
        }
    }
}