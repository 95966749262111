.news-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  ul {
    list-style: none;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .news-pagination {
    margin: 2rem 0;
  }
}

// @media screen and (max-width: 1024px) {
//   .news-list {
//     ul {
//       grid-template-columns: 1fr 1fr 1fr;
//     }
//   }
// }

// @media screen and (max-width: 768px) {
//   .news-list {
//     gap: 30px;

//     ul {
//       grid-template-columns: 1fr 1fr;
//     }
//   }
// }

// @media screen and (max-width: 550px) {
//   .news-list {
//     gap: 20px;

//     ul {
//       grid-template-columns: 1fr;
//     }
//   }
// }
