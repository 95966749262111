.confirm{
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 23rem;
    align-items: center;

    @media screen and (max-width:768px) {
        padding-bottom: 7rem;
    }

    .confirm__img{
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
    }
    .confirm__title{
        font-size: 2rem;
        line-height: 2.3rem;
        color: #101828;
        font-weight: 500;
        margin-bottom: 0;
    }
    .confirm__info{
        color:#667085;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 2rem;
    }
    .confirm__input{
        @media screen and (max-width:768px) {
            width: 3.4rem;
            height: 4rem;
        }
        @media screen and (max-width:425px) {
            width: 3rem;
            height: 3.5rem;
        }
        @media screen and (max-width:355px) {
            width: 2.7rem;
            height: 3.5rem;
        }
    }
    .confirm__code{
        color: #344054;
        font-size: 1rem;
        align-self: flex-start;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .confirmBtn, .confirm__resendBtnTrue{
        background-color: #489FE1;
        color: #fff;
        border: none;
        margin-bottom: 2rem;
        width: 22.5rem;
        @media screen and (max-width:768px) {
            width: 22.5rem;
        }
        @media screen and (max-width:425px) {
            width: 20rem;
        }
        @media screen and (max-width:320px) {
            width: 19rem;
        }
    }
    .confirmBtnDisabled, .confirm__resendBtnFalse{
        cursor: auto;
        background-color: #9EA7AF;
        color: #fff;
        border: none;
        margin-bottom: 2rem;
        width: 22.5rem;
        @media screen and (max-width:768px) {
            width: 22.5rem;
        }
        @media screen and (max-width:425px) {
            width: 20rem;
        }
        @media screen and (max-width:320px) {
            width: 19rem;
        }
    }
    .confirm__text{
        margin-bottom: 2rem;
    }
    .confirm__timer{
        color: #3695D8;
    }
}
.error-status{
    color: #F04438;
    font-size: 14px;
    font-weight: 600;
    align-self: flex-start;
}