.container{
    width: 90vw;
    margin: 0 auto;
    min-height: 100vh;
}
.news{
    display: flex;
    flex-direction: column;  
}

.news h1{
    text-align: center;
}