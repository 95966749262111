.recovery {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 23rem;
    align-items: center;

    @media screen and (max-width:800px) {
        padding-top: 6.6rem;
        padding-bottom: 11rem;
        width: 22.5rem;
    }
    @media screen and (max-width:400px) {
        width: 21rem;
    }
    @media screen and (max-width:355px) {
        width: 20rem;
    }
    @media screen and (max-width:330px) {
        width: 19rem;
    }

    .recovery__img {
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
    }

    .recovery__title {
        font-size: 2rem;
        line-height: 2.3rem;
        color: #101828;
        font-weight: 700;
        margin-bottom: 2rem;
        text-align: center;
    }

    .recovery__new-password {
        font-size: 14px;
        font-weight: 400;
        color: #344054;
        align-self: flex-start;
        margin-bottom: 6px;
    }

    .recovery__changePasswordInp {
        width: 28vw;
        border: 1px solid#D0D5DD;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 10px 14px;
        margin-bottom: 0;
        box-shadow: 0px 1px 2px 0px #1018280D;
        @media screen and (max-width:800px) {
            width: 22.5rem;
        }
        @media screen and (max-width:400px) {
            width: 20rem;
        }
        @media screen and (max-width:355px) {
            width: 19rem;
        }
        @media screen and (max-width:330px) {
            width: 18rem;
        }
    }

    .recovery__errorInp {
        width: 28vw;
        border: 1px solid #F04438;
        font-size: 1rem;
        border-radius: 8px;
        margin-bottom: 0rem;
        box-shadow: 0px 1px 2px 0px #1018280D;
        color: #101828;
        font-weight: 400;
        padding: 10px 14px;

        &:focus {
            border: 2px solid #0077B6;
            outline: none;
        }
        @media screen and (max-width:800px) {
            width: 22.5rem;
        }
        @media screen and (max-width:400px) {
            width: 20rem;
        }
        @media screen and (max-width:355px) {
            width: 19rem;
        }
        @media screen and (max-width:330px) {
            width: 18rem;
        }
    }
}

.recovery__info {
    font-size: 14px;
    font-weight: 400;
    color: #475467;
    padding: 0;
    margin: 0;
    align-self: flex-start;
}

.recovery__btn {
    cursor: pointer;
    background-color: #489FE1;
    color: #fff;
    border: none;
    margin-bottom: 2rem;
    width: 30vw;
    height: 2.75rem;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 2rem;
    @media screen and (max-width:800px) {
        width: 22.5rem;
    }
    @media screen and (max-width:400px) {
        width: 21rem;
    }
    @media screen and (max-width:355px) {
        width: 20rem;
    }
    @media screen and (max-width:330px) {
        width: 19rem;
    }
}

.recovery__passwordBlock {
    position: relative;

    .recovery__showEye {
        position: absolute;
        top: 8px;
        right: 12px;
        cursor: pointer;
    }
}
.recovery__error-text {
    color: #F04438;
    font-size: 14px
}
