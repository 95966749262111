.verification-info {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .verification-info__title {
        font-size: 3rem;
    }

    .verification-info__btn {
        margin-top: 2.8rem;
        width: 18.3rem;
        background-color: #3998E8;
        color: #fff;
        padding: 15px 10px;
        border: none;
        border-radius: 8px;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 2rem;
        cursor: pointer;
    }
}