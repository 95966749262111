.petition-details {
    padding-top: 6.9rem;
    background-color: #FAFCFF;

    h1 {
        text-align: center;
        margin-bottom: 2rem;
    }

    .petition-details__tags{
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        .petition-details__tag{
            background-color: #DBE5FC;
            color: #0B1F33;
            font-size: 14px;
            font-weight: 600;
            padding: 2px 10px;
            border-radius: 16px;
        }
    }

    .petition-details__images {
        display: flex;
        justify-content: space-between;

        .petition-details__petition-img {
            width: 60vw;
            height: 32rem;
            object-fit: cover;
            border-radius: 15px;
            margin-bottom: 2.8rem;
        }

        .petition-details__votes {
            width: 21rem;
            //height: 15rem;
            padding: 1.25rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            box-shadow: 0px 1px 4px 0px #E3EBFC;
            border-radius: 15px;

            .votes__progressBar-container {
                width: 21.5rem;
                height: 1.5rem;
                background-color: #F2F4F7;
                border-radius: 4px;

                .votes__progressBar {
                    height: 100%;
                    width: 0;
                    background-color: #007bff;
                    transition: width 0.3s ease;
                }

                .votes__progress-bar-fill {
                    height: 100%;

                }
            }

            .votes__count {
                display: flex;
                justify-content: space-between;

                .votes__res {
                    color: #3695D8;
                    font-weight: 700;
                    font-size: 1.5rem
                }

                .votes__total {
                    font-weight: 700;
                    font-size: 1.5rem
                }
            }

            .votes__author {
                font-weight: 700;
                font-size: 1rem;
                display: flex;
                align-items: center;

                img{
                    margin-right: 12px; 
                }
            }
            .votes__login{
                color: #7D8D93; 
                font-size: 14px;
            }
            .votes__login-link{
                color: #3695D8; 
                cursor: pointer;
            }
            .votes__btns {
                margin-top: 2.375rem;

                .votes__btns-block {
                    display: flex;
                    gap: 3.875rem;
                    align-items: center;
                    margin-bottom: 2rem;
                }

                .votes__btn-for {
                    background-color: #3695D8;
                    width: 11.75rem;
                    height: 3.5rem;
                    border-radius: 15px;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                .votes__btn-for.voted {
                    background-color: #9EA7AF;
                }

                .votes__btn-against {
                    background-color: #FF5A3F;
                    width: 11.75rem;
                    height: 3.5rem;
                    border-radius: 15px;
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                .votes__btn-against.voted {
                    background-color: #9EA7AF;
                }

                .votes__quantity {
                    color: #7D8D93;
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            .votes__cancelBtn {
                background-color: #FFCB14;
                width: 11.75rem;
                height: 3.5rem;
                border-radius: 15px;
                border: none;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                margin-top: 2rem;
                cursor: pointer;
            }
        }
    }

    .petition-details__info {
        color: #666666;
        margin-bottom: 3.5rem;

        .petition-details__calendar {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .petition-details__petition {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 1px 4px 0px #E3EBFC;
        padding: 2rem;
        margin-bottom: 6.6rem;
    }

    .petition-details__buttons {
        display: flex;
        gap: 1rem;
        padding-bottom: 11.3rem;

        .buttons__edit {
            color: #3695D8;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 2.9rem;
            background-color: #fff;
            font-weight: 600;
            font-size: 1.125rem;
            cursor: pointer;
        }

        .buttons__publish {
            background-color: #3695D8;
            color: #fff;
            font-weight: 600;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 0.7rem;
            font-size: 1.125rem;
            cursor: pointer;
        }
    }
    .petition-details__author{
        color: #0077FF;
        font-weight: 700;
        text-decoration: underline;
    }
}