#gjs {
  border: 3px solid #444;
}

.gjs-cv-canvas {
  top: 10px;
  width: 100%;
  height: 80vh;
}
.gjs-block {
    width: auto;
    height: auto;
    min-height: auto;
  }
.constructor{
  margin-top: 1rem;
}