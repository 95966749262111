.contacts {
  background-color: #fafcff;
  display: flex;
  flex-direction: column;

  &__info {
    display: flex;
    width: 90%;
    align-self: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 0;
  }

  &__form {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    margin: 0 auto;

    @media screen and (max-width: 1130px) {
      width: 50vw;
    }
    @media screen and (max-width: 535px) {
      width: 80vw;
    }
  }

  &__map {
    width: 90%;
    height: 400px;
    align-self: center;
    object-fit: cover;
    margin-top: 50px;
    margin-bottom: 80px;
  }
}

.input-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
  width: 100%;
}
.inp-block {
  width: 100%;
}
.inp-block input {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 95%;
  height: 2rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: 0.4rem;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-field {
  border: 1px solid #d0d5dd;
}
.input-field-err {
  border: 1px solid red;
}

.inp-block label {
  font-size: 14px;
  font-weight: 500;
}
.inp-block textarea {
  height: 120px;
  width: 95%;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.inp-block-textarea {
  border: 1px solid #d0d5dd;
}
.inp-block-textarea-err {
  border: 1px solid red;
}
.btn-container {
  width: 100%;
  margin: 0 auto;
}
.contacts__button {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #3695d8;
  background-color: #3695d8;
  color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: background-color 0.3s, color 0.3s;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.error-message {
  color: red;
}
