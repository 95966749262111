.news-card {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border-radius: 15px;
  box-shadow: 0 1px 4px #d4d7de;
  padding: 1rem;

  img {
    width: 100%;
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    object-fit: cover;
  }

  &__date {
    width: fit-content;
    padding: 8px 12px;
    background-color: rgb(72, 159, 225);
    font-size: 14px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    h4 {
      font-size: 24px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &__more-btn {
    width: fit-content;
    padding: 8px 12px;
    border: 2px solid #3b3b3b;
    border-radius: 3px;
    color: #3b3b3b;
    font-weight: 600;
    text-decoration: none;
  }
}
