.home {
    background-color: #FAFCFF;
    padding-bottom: 10rem;

    main {
        padding-top: 4.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 800px) {
            padding-top: 1.6rem;
        }

        .home__main-block {
            width: 90vw;
            height: 27rem;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url('../../components/ui/images/main-page.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 10px;
            padding-top: 2rem;
            padding-bottom: 2rem;

            @media screen and (max-width: 1220px) {
                height: 35rem;
            }
            @media screen and (max-width: 650px) {
                height: 50rem;
            }
            @media screen and (max-width: 425px) {
                padding-top: 0;
                height: 45rem;
            }

            .home__main-content {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                gap: 5rem;
                @media screen and (max-width: 1220px) {
                    gap: 2rem;
                }
            }

            // .home__main-img {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     z-index: 1;
            // }
            .home__main-left{
                // z-index: 999;
                color: #fff;
                width: 40%;
                font-size: 18px;
                @media screen and (max-width: 650px) {
                    width: 70%;
                }
                @media screen and (max-width: 425px) {
                    width: 90%;
                    text-align: center;
                }
            }

            .home__main-buttons {
                display: flex;
                gap: 1.6rem;   
                @media screen and (max-width: 650px) {
                    gap: 1rem;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                }             

                .button__submit,
                .button__search {
                    background-color: transparent;
                    border: 1px solid #fff;
                    width: 15.6rem;
                    height: 8.4rem;
                    padding: 3.5rem 6rem 1.5rem 1.5rem;
                    text-align: left;
                    transition: 0.3s ease-in;
                    border-radius: 13px;
                    color: #fff;
                    font-size: 1.125rem;
                    cursor: pointer;
                    // z-index: 2;
                    position: relative;
                    @media screen and (max-width: 650px) {
                        width: 65%;
                    }
                    @media screen and (max-width: 425px) {
                        width: 75%;
                        padding: 0 0 0 1.5rem
                    }

                    &:hover {
                        background-color: #fff;
                        color: #3695D8;
                        transition: 0.3s ease-in;
                        // z-index: 5;
                    }
                    &:hover img:last-child{
                        opacity: 0;
                    }
                }
            }
        }
    }

    .petitions {
        background-color: #FAFCFF;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding-bottom: 5rem;

        h3 {
            font-size: 2.5rem;
            text-align: center;
            margin-bottom: 2rem;

            @media screen and (max-width: 768px) {
                margin-top: 0;
                margin-bottom: 2rem;
            }
        }

        .home__buttons {
            display: flex;
            gap: 1rem;

            button {
                background-color: #E3EBFC;
                border-radius: 24px;
                padding: 9px 1.375rem;
                border: none;
                color: #0B1F33;
                font-size: 13px;
                font-weight: 400;
                line-height: 22px;
                cursor: pointer;

                &:hover {
                    background-color: #2039B8;
                    color: #fff;
                    transition: 0.5s ease-in;
                }
            }
        }

        a {
            display: flex;
            justify-content: flex-end;
            gap: 0.8rem;
            color: #3695D8;
            font-size: 1.25rem;
            margin-top: 5rem;
            cursor: pointer;
        }
    }

    .steps {
        background-color: #FAFCFF;

        .steps__title {
            text-align: center;
            font-size: 2.5rem;
            margin-top: 0;
            margin-bottom: 4rem;

            @media screen and (max-width: 768px) {
                margin-bottom: 2rem;
            }
        }

        .steps__blocks {
            display: flex;
            flex-wrap: wrap;
            gap: 4.5rem;

            @media screen and (max-width: 768px) {
                gap: 1.25rem;
                justify-content: center;
            }

            .steps__block {
                background-color: #fff;
                box-shadow: 0px 1px 4px 0px #E3EBFC;
                border-radius: 5px;
                width: 26%;
                padding-top: 2rem;
                padding-left: 2.5rem;

                @media screen and (max-width: 1200px) {
                    width: 30%;
                }

                @media screen and (max-width: 900px) {
                    width: 35%;
                }

                @media screen and (max-width: 768px) {
                    width: 40%;
                }

                @media screen and (max-width: 620px) {
                    width: 75%;
                }

                @media screen and (max-width: 425px) {
                    width: 85%;
                }

                .steps__step {
                    background-color: #E3EBFC;
                    color: #2039B8;
                    width: 5.5rem;
                    height: 2rem;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .steps__petition {
                    display: flex;
                    gap: 1.875rem;
                    font-size: 1.25rem;

                    p {
                        width: 14.6rem;
                    }
                }

                .steps__info {
                    color: #666F8F;
                    font-size: 1rem;
                    width: 14.6rem;
                }
            }
        }

        .steps__btn {
            margin-top: 4.125rem;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 768px) {
                margin-top: 2rem;
                justify-content: center;
            }

            button {
                background-color: #3695D8;
                width: 17.5rem;
                height: 3.125rem;
                border-radius: 9px;
                border: none;
                color: #fff;
                font-size: 1rem;
                cursor: pointer;
            }
        }

        .petition-modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.75);
            overflow: hidden;
            overflow-y: auto;
            transition: opacity 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .petition-modal__content {
            // position: absolute;
            // top: 20rem;
            // left: 28rem;
            box-shadow: 0px 4px 8px 0px #10184014;
            border-radius: 8px;
            background-color: #fff;
            padding: 1rem 1.25rem;

            .petition-modal-top {
                display: flex;
                justify-content: space-between;
                font-weight: 600;
                color: #282A2F;
                margin-bottom: 1rem;

                img {
                    cursor: pointer;
                }
            }

            .petition-modal__middle {
                font-size: 12px;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            hr {
                margin-bottom: 1rem;
            }

            .petition-modal__bottom {
                display: flex;
                justify-content: flex-end;
                gap: 0.5rem;

                .petition-modal__cancel {
                    background-color: #fff;
                    border: 1px solid #B7B7B7;
                    box-shadow: 0px 1px 2px 0px #266EFF0D;
                    font-size: 12px;
                    color: #000000;
                    padding: 0.5rem 1rem;
                    border-radius: 3px;
                    cursor: pointer;
                }

                .petition-modal__sign-in {
                    background-color: #3695D8;
                    color: #fff;
                    box-shadow: 0px 1px 2px 0px #266EFF0D;
                    border-radius: 3px;
                    border: none;
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                }
            }
        }
    }

    .analytics {
        background-color: #FAFCFF;
        padding-top: 7.8rem;

        @media screen and (max-width: 768px) {
            padding-top: 6.25rem;
        }

        .analytics__title {
            font-size: 2.5rem;
            margin-bottom: 2rem;
            text-align: center;
        }

        .analytics__blocks {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;

            .analytics__block {
                background-color: #fff;
                box-shadow: 0px 1px 4px 0px #E3EBFC;
                border-radius: 15px;
                padding: 1.5rem;
                width: 21rem;
                display: flex;
                flex-direction: column;

                img {
                    width: 100%;
                }

                h5 {
                    font-size: 1.5rem;
                    margin: 0;
                }

                p {
                    color: #66727F;
                    font-size: 1rem;
                }

                .analytics__date {
                    font-size: 0.875rem;
                }
            }
        }

        .analytics__btn {
            margin-left: auto;
            margin-top: 5rem;
            color: #3695D8;
            height: 3.125rem;
            width: 17.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #3695D8;
            border-radius: 8px;
            background-color: transparent;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
                background-color: #3695D8;
                color: #fff;
                transition: 0.3s ease-in;
            }
        }
    }
}

.petitions__btn {
    width: 17.5rem;
    height: 3.125rem;
    border: 1px solid #3695D8;
    border-radius: 8px;
    background-color: transparent;
    color: #3695D8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    gap: 1rem;
}