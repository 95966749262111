.profile{
    .profile__options{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 7.6rem;
        margin-bottom: 6rem;
        @media screen and (max-width:500px) {
            margin-bottom: 2rem;
        }

        .profile__links{
            display: flex;
            gap: 2.875rem;

            .profile__navLink{
                text-decoration: none;
                color: #979797;
                font-weight: 400;
                font-size: 1.25rem;

                span{
                    background-color: #E0EDF8;
                    color: #175CD3;
                    border-radius: 50%;
                    padding: 0 5px;
                    font-size: 0.875rem;
                }
            }
        }
        .profile__status{
            color: #14BA6D;
            font-weight: 800;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 1rem;
            @media screen and (max-width:647px) {
                padding-top: 2rem; 
            }
        }
    }
    .profile__steps-container{
        display: flex;
        justify-content: center;
        .profile__steps{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 34rem;
            height: 4.75rem;
            border: 1px solid #DDDEE2;
            border-radius: 8px;
            font-size: 1.25rem;
            margin-bottom: 4.3rem;
            font-weight: 700;
            padding: 0.9rem;
            span{
                color: #fff;
                background-color: #3695D8;
                border-radius: 50%;
                padding: 0 8px;
                margin-right: 0.5rem;
            }
            .profile__second{
                color: #8D8D99;
            }
            @media screen and (max-width:500px) {
                border: none;
                font-size: 14px;
            }
        }
    }
    .profile__selectRegion {
        width: 100%;
        max-width: 70vw;
        padding: 1rem;
        border: 1px solid #DDDEE2;
        border-radius: 8px;
        font-size: 1.125rem;
        font-weight: 400;
        color: black;
        margin-bottom: 1.5rem;
        appearance: none;
        background-image: url('../../ui/images/Frame\ 10.svg');
        background-repeat: no-repeat;
        background-position: right 1rem center;
    }
    

    .profile__data{
        .profile__data-ava{
            width: 8.4rem;
            height: 8.4rem;
            border-radius: 50%;
            margin-bottom: 1.5rem;
        }
        .profile__avaError{
            width: 8.4rem;
            height: 8.4rem;
            border-radius: 50%;
            margin-bottom: 1.5rem;
            border: 13px solid #FDA29B;
        }
        form{
            display: flex;
            flex-direction: column;
            label{
                color:#686B7C;
                font-size: 1.125rem;
                font-weight: 400;
                margin-bottom: 0.5rem;
            }
            .profile__inputs{
                display: flex;
                gap: 1rem;
                align-items: center;
                img{
                    cursor: pointer;
                }
            }
            input{
                width: 70vw;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: black;
                margin-bottom: 1.5rem;
            }
            .profile__Error{
                width: 70vw;
                padding: 1rem;
                border: 3px solid #FDA29B;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: black;
                margin-bottom: 1.5rem;
            }
            img{
                width: 1.5rem;
                height: 1.5rem;
            }
            .profile__selectGender{
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                background-position-x: 69vw;
                width: 70vw;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                margin-bottom: 1.5rem; 
                color: #000000;
            }
            .profile__genderErr{
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                background-position-x: 69vw;
                width: 70vw;
                padding: 1rem;
                border: 3px solid #FDA29B;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: #667085;
                margin-bottom: 1.5rem; 
            }
            .profile__selectedGender{
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                background-position-x: 69vw;
                width: 70vw;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: black;
                margin-bottom: 1.5rem; 
            }
            
            .inputErr{
                border: 3px solid #FDA29B;
            }
            .profile__selectMonth{
                width: 13rem;
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: #667085;
                margin-bottom: 1.5rem; 
            }
            .profile__selectedMonth{
                width: 13rem;
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: black;
                margin-bottom: 1.5rem; 
            }
            .profile__selectMonthErr{
                width: 13rem;
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 1rem;
                border: 3px solid #FDA29B;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: #667085;
                margin-bottom: 1.5rem;
            }
            .profile__selectYear{
                width: 10rem;
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: #667085;
                margin-bottom: 1.5rem;
            }
            .profile__selectedYear{
                width: 10rem;
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 1rem;
                border: 1px solid #DDDEE2;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: black;
                margin-bottom: 1.5rem;
            }
            .profile__selectYearErr{
                width: 10rem;
                appearance: none;
                background-image: url('../../ui/images/Frame\ 10.svg');
                background-repeat: no-repeat;
                background-position: right center;
                padding: 1rem;
                border: 3px solid #FDA29B;
                border-radius: 8px;
                font-size: 1.125rem;
                font-weight: 400;
                color: #667085;
                margin-bottom: 1.5rem;
            }
            .profile__save-btn{
                margin-top: 4.8rem;
                width: 14.3rem;
                background-color: #3998E8;
                color: #fff;
                padding: 10px;
                border: none;
                border-radius: 8px;
                font-size: 1rem;
                font-weight: 800;
                margin-bottom: 8.8rem;
                cursor: pointer;
                transition: 0.3s ease-in;
                &:hover{
                    background-color: #2478bd;
                }
            }
        }
    }
    .profile-logout-btn{
        margin-bottom: 2.9rem;
        color: #E64452;
        border: 1px solid #E64452;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        display: flex;
        margin-left: auto;
        cursor: pointer;
    }
}
.dark-bg{
    opacity: 60%;
    color: #000000;
}
.change-password {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    .change-password__content {
            width: 25rem;
            background-color: #fff;
            box-shadow: 0px 8px 8px -4px #1018280A;
            box-shadow: 0px 20px 24px -4px #1018281A;
            border-radius: 12px;
            padding: 1.5rem;
    
            .change-password__label {
                color: #344054;
                font-weight: 800;
                font-size: 1rem;
            }

            .change-password__inpBlock{
                position: relative;
                .change-password__inp {
                    width: 22rem;
                    margin-top: 0.4rem;
                }
                .change-password__showEye{
                    position: absolute;
                    top: 1.2rem;
                    right: 2rem;
                    cursor: pointer;
                }
            }
        }

    
    .change-password__save{
        background-color: #489FE1;
        color: #fff;
        border-radius: 8px;
        width: 24rem;
        height: 2.75rem;
        border: none;
        margin-bottom: 12px;
        cursor: pointer;
        transition: 0.3s ease;
        font-weight: 800;
        font-size: 1rem;

        &:hover{
            background-color: #3580b9;
        }
    }
    .change-password__cancel{
        background-color: #fff;
        border: 1px solid#D0D5DD;
        width: 24rem;
        height: 2.75rem;
        border-radius: 8px;
        color: #344054;
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s ease;
        font-weight: 800;
        font-size: 1rem;

        &:hover{
            background-color: #D0D5DD;
        }
    }
}
.change-email{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    .change-email__content{
        width: 25rem;
            background-color: #fff;
            box-shadow: 0px 8px 8px -4px #1018280A;
            box-shadow: 0px 20px 24px -4px #1018281A;
            border-radius: 12px;
            padding: 1.5rem;
    
            .change-email__label {
                color: #344054;
                font-weight: 600;
            }
    }
    .change-email__inp {
        width: 22rem;
    }
    .change-email__save{
        background-color: #489FE1;
        color: #fff;
        border-radius: 8px;
        width: 24rem;
        height: 2.75rem;
        border: none;
        margin-bottom: 12px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover{
            background-color: #3580b9;
        }
    }
    .change-password__cancel{
        background-color: #fff;
        border: 1px solid#D0D5DD;
        width: 24rem;
        height: 2.75rem;
        border-radius: 8px;
        color: #344054;
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover{
            background-color: #D0D5DD;
        }
    }
}
.logout-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .logout-modal__content {
        box-shadow: 0px 4px 8px 0px #10184014;
        border-radius: 8px;
        background-color: #fff;
        padding: 1rem 1.25rem;

        .logout-modal__top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            color: #101828;

            img{
                object-fit: cover;
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
            }
        }
        .logout-modal__middle{
            font-size: 14px;
            font-weight: 400;
            color: #475467;
            margin-bottom: 2rem;
        }
        .logout-modal__bottom{
            display: flex;
            justify-content: space-between;

            .logout-modal__cancel{
                width: 9rem;
                height: 2.5rem;
                background-color: #fff;
                color: #344054;
                font-weight: 600;
                font-size: 1rem;
                border: 1px solid 
                #D0D5DD;
                border-radius: 8px;
                cursor: pointer;
            }
            .logout-modal__btn{
                width: 9rem;
                height: 2.5rem;
                background-color:#D92D20;
                color: #fff;
                font-weight: 600;
                font-size: 1rem;
                border: none;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}
.recovery__error-text {
    color: #F04438;
    font-size: 14px
}
.profile__birthInps{
    display: flex;
    gap: 1.4rem;
    @media screen and (max-width:425px) {
        gap: 0.5rem;
    }
}
#profile__dateInp{
    width: 4.8rem;
    @media screen and (max-width:425px) {
        width: 3rem;
    }
}