.dnsRecords {
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;

    .dnsRecords__sidebar {
        flex: 1;
        height: 70vh;
        box-shadow: 0px 8px 8px -4px #1018280A;
        border-radius: 12px;
        background-color: #d8e6f1;
        .sidebar__title{
            text-align: center;
            color: #0077B6;
        }
        .dnsRecords__domain-list{
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 0.3rem;

            .dnsRecords__domain-item{
                display: flex;
                gap: 1rem;
                justify-content: center;
                background-color: #fff;
                border: 1px solid rgb(151, 145, 145);
                width: 80%;
                padding: 0.5rem 0;
                border-radius: 10px;
                box-shadow: 0px 8px 8px -4px #1018280A;
            }
            .btn-delete{
            cursor: pointer;
            background-color: #E64452;
            color: #fff;
            border-radius: 8px;
            font-size: 0.8rem;
            font-weight: 500;
            border: none;
            line-height: 1.5rem;
            }
        }
    }

    .dnsRecords__add {
        flex: 2;
        align-items: center;
        justify-content: center;
    }

    .dnsRecords__title {
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    .dnsRecords__form {
        display: flex;
        flex-direction: column;
        width: 30vw;

        .dnsRecords__label {
            font-size: 1rem;
            color: #344054;
            margin-bottom: 0.4rem;
        }

        .dnsRecords__inp {
            border-radius: 8px;
            border: 1px solid #D0D5DD;
            margin-bottom: 1rem;
            box-shadow: 0px 1px 2px 0px #1018280D;
            font-size: 1rem;
            color: #667085;
            padding: 0.625rem 0.875rem;

            &:focus {
                border: 2px solid #0077B6;
                outline: none;
            }
        }

        .dnsRecords__btn {
            margin-bottom: 7rem;
            cursor: pointer;
            background-color: #489FE1;
            color: #fff;
            border-radius: 8px;
            font-size: 1rem;
            font-weight: 600;
            border: none;
            padding: 0.625rem 0;
            line-height: 1.5rem;
            margin-top: 1.5rem;
        }
    }
}