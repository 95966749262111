.news-details {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 90vw;
    margin: 0 auto;
  
    &__img {
      align-self: center;
      #news-img {
        margin-bottom: 30px;
        width: 100%;
        max-width: 1072px;
        height: 330px;
        border-radius: 12px;
        object-fit: cover;
      }
    }
  
    &__date-and-reviews {
      display: flex;
      align-items: center;
      gap: 80px;
    }
  
    &__date,
    &__reviews {
      display: flex;
      align-items: center;
      gap: 6px;
  
      p {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(62, 50, 50, 0.75);
      }
    }
  
    &__text {
      width: 100%;
      max-width: 1072px;
      align-self: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: rgb(62, 50, 50);
      -pre-linewhite-space: pre-line;
  
      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
      }
  
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .news-details {
      gap: 30px;
  
      &__img {
        #news-img {
          margin-bottom: 25px;
          height: 392px;
        }
      }
  
      &__text {
        h3 {
          font-size: 20px;
          line-height: 24px;
        }
  
        p {
          font-size: 18px;
          line-height: 34px;
        }
      }
    }
  }
  
  @media screen and (max-width: 550px) {
    .news-details {
      gap: 20px;
  
      &__img {
        #news-img {
          margin-bottom: 20px;
          height: 231px;
        }
      }
  
      &__text {
        p {
          font-size: 16px;
          line-height: 140%;
        }
      }
    }
  }
  