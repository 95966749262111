.notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 13rem;

    @media screen and (max-width: 425px) {
        padding-top: 3rem;
        padding-bottom: 10rem;
    }

    img{
        width: 100%;
    }

    .notFound__btn{
        width: 20rem;
        height: 3.4rem;
        background-color: #3695D8;
        border-radius: 13px;
        border: none;
        color: #fff;
        cursor: pointer;
        margin-top: 3rem;
        @media screen and (max-width: 320px) {
            margin-top: 1.5rem;
            width: 19rem;
        }
    }
}