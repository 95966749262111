.changePassword {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    width: 25rem;
    height: 70vh;
    background-color: #fff;
    box-shadow: 0px 8px 8px -4px #1018280a;
    box-shadow: 0px 20px 24px -4px #1018281a;
    border-radius: 12px;
    padding: 1.5rem;
    @media screen and (max-width: 500px) {
      width: 21rem;
    }
    @media screen and (max-width: 400px) {
      width: 18rem;
    }
    @media screen and (max-width: 350px) {
      width: 16.5rem;
    }
  }
  &__label {
    color: #344054;
    font-weight: 800;
    font-size: 1rem;
  }

  &__inpBlock {
    position: relative;
  }

  &__inpError {
    width: 22rem;
    border: 1px solid #fda29b;
  }
  &__showEye {
    position: absolute;
    top: 1.2rem;
    right: 2rem;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      right: 1rem;
    }
    @media screen and (max-width: 400px) {
      right: 5px;
    }
  }
}
.changePassword__inp {
  width: 22rem;
  margin-top: 0.4rem;
  padding: 1rem;
  border: 1px solid #dddee2;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 400;
  color: black;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 500px) {
    width: 20rem;
    padding-right: 0;
  }
  @media screen and (max-width: 400px) {
    width: 18rem;
  }
  @media screen and (max-width: 350px) {
    width: 16.5rem;
  }
}
.changePassword__save {
  background-color: #489fe1;
  color: #fff;
  border-radius: 8px;
  width: 24rem;
  height: 2.75rem;
  border: none;
  margin-bottom: 12px;
  cursor: pointer;
  transition: 0.3s ease;
  font-weight: 800;
  font-size: 1rem;

  &:hover {
    background-color: #3580b9;
  }
  @media screen and (max-width: 500px) {
    width: 20rem;
  }
  @media screen and (max-width: 400px) {
    width: 18rem;
  }
  @media screen and (max-width: 350px) {
    width: 16.5rem;
  }
}
.changePassword__cancel {
  background-color: #fff;
  border: 1px solid#D0D5DD;
  width: 24rem;
  height: 2.75rem;
  border-radius: 8px;
  color: #344054;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
  font-weight: 800;
  font-size: 1rem;

  &:hover {
    background-color: #d0d5dd;
  }
  @media screen and (max-width: 500px) {
    width: 20rem;
  }
  @media screen and (max-width: 400px) {
    width: 18rem;
  }
  @media screen and (max-width: 350px) {
    width: 16.5rem;
  }
}

.recovery__error-text {
  color: #f04438;
  font-size: 14px;
  margin-bottom: 1rem;
}
