.verificationCamera__btn {
    margin-top: 4.8rem;
    width: 14.3rem;
    background-color: #3998E8;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 800;
    cursor: pointer;
    transition: 0.3s ease-in;
    margin-bottom: 2rem;

    &:hover {
        background-color: #2478bd;
    }
}
.verificationCamera__btn-cancel{
    width: 14.3rem;
    background-color: #FF5A3F;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 800;
    cursor: pointer;
    transition: 0.3s ease-in;
    margin-bottom: 2rem;

    &:hover {
        background-color: #f37764;
    }
}