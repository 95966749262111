.footer {
    display: flex;
    width: 100%;
    padding: 64px 10px 25px 10px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background: #0f172a;
    overflow-x: hidden;
    box-sizing: border-box;
}

.footer .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer .content .linkSide {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
}

.footer .content .logo {
    align-self: flex-start;
    margin: 0;
}

.footer .links a,
.project a {
    /* font-family: Inter; */
    color: var(--Black, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
}

.links p {
    /* font-family: Inter; */
    color: var(--Black, #c4c4c4);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
}

.project p {
    /* font-family: Inter; */
    color: var(--Black, #c4c4c4);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
}

.footer .links,
.project {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 177px;
}

.socials {
    width: 360px;
}

.socials h1 {
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--Black, #c4c4c4);
    margin-bottom: 16px;
}

.socials .icons {
    display: flex;
    gap: 16px;
}

.footer .copyright {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.footer .copyright p {
    color: #98a2b3;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.footer__apps-btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer__apps-btns a {
    margin-bottom: 20px;
}

.footer__apps-btns a:last-child {
    margin-bottom: 0;
}