.verficationModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    .verficationModal__content {
        box-shadow: 0px 4px 8px 0px #10184014;
        border-radius: 8px;
        background-color: #fff;
        padding: 4rem 1.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .verficationModal__btn {
            background-color: #489FE1;
            color: #fff;
            border-radius: 8px;
            width: 10rem;
            height: 2.75rem;
            border: none;
            margin-bottom: 12px;
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                background-color: #3580b9;
            }
        }
    }
}