.navbar {
  background-color: #fafcff;
  box-shadow: 0 4px 6px #0000001a;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .navbar__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .navbar__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      &__first {
        width: 138px;
      }
      &_last {
        margin-top: 30px;
        height: 120px;
        height: 120px;
        object-fit: cover;
        display: inline-block;
      }
      .navbar__lang {
        button {
          background-color: #fafcff;
          border: none;
          cursor: pointer;
        }

        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
    }

    .navbar__menu {
      .navbar__list {
        display: flex;
        gap: 2.5rem;
        list-style-type: none;
        font-size: 0.875rem;
        line-height: 1.5rem;
        letter-spacing: 0.5px;

        .nav-link {
          text-decoration: none;
          color: #66727f;
        }

        @media screen and (max-width: 1000px) {
          gap: 1rem;
          margin-right: 0;
          margin-left: 0;
        }

        @media screen and (max-width: 1350px) {
          display: none;
        }
      }
    }

    .petition-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.75);
      overflow: hidden;
      overflow-y: auto;
      transition: opacity 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
    }

    .petition-modal__content {
      box-shadow: 0px 4px 8px 0px #10184014;
      border-radius: 8px;
      background-color: #fff;
      padding: 1rem 1.25rem;
      width: 23.8rem;
      @media screen and (max-width: 550px) {
        width: 22rem;
      }
      @media screen and (max-width: 400px) {
        width: 19rem;
      }
      @media screen and (max-width: 350px) {
        width: 17rem;
      }

      .petition-modal-top {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        color: #282a2f;
        margin-bottom: 1rem;

        img {
          cursor: pointer;
        }
      }

      .petition-modal__middle {
        font-size: 12px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      hr {
        margin-bottom: 1rem;
      }

      .petition-modal__bottom {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        .petition-modal__cancel {
          background-color: #fff;
          border: 1px solid #b7b7b7;
          box-shadow: 0px 1px 2px 0px #266eff0d;
          font-size: 12px;
          color: #000000;
          padding: 0.5rem 1rem;
          border-radius: 3px;
          cursor: pointer;
        }

        .petition-modal__sign-in {
          background-color: #3695d8;
          color: #fff;
          box-shadow: 0px 1px 2px 0px #266eff0d;
          border-radius: 3px;
          border: none;
          padding: 0.5rem 1rem;
          cursor: pointer;
        }
      }
    }

    .navbar__button {
      display: flex;
      gap: 1rem;

      button {
        width: 10.375rem;
        height: 4rem;
        background-color: #fff;
        color: #3498db;
        border-radius: 15px;
        cursor: pointer;
        transition: 0.2s ease-in;
        font-size: 14px;
        transition: background-color 0.3s, color 0.3s;
        border: 3px solid #3695d8;

        &:hover {
          background-color: #3695d8;
          color: white;
        }

        // &:last-child {
        //     width: 5.3rem;
        //     background-color: #fff;
        //     border: 2px solid #3695D8;
        //     color: #3695D8;

        //     @media screen and (max-width: 1000px) {
        //         width: 5rem;
        //     }
        // }

        @media screen and (max-width: 1000px) {
          width: 8rem;
        }
      }

      @media screen and (max-width: 1350px) {
        display: none;
      }
    }

    .navbar__burgerMenu {
      display: none;

      @media screen and (max-width: 1350px) {
        display: block;
      }
    }
  }

  .navbar__dropdown {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    position: relative;

    .navbar__select {
      position: absolute;
      top: 30px;
      left: 0;
      border: 1px solid #f2f4f7;
      width: 8rem;
      height: 8.5rem;
      border-radius: 8px;
      box-shadow: 0px 4px 6px -2px #10182808;
      box-shadow: 0px 12px 16px -4px #10182814;
      background-color: #ffffff;
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 10px 14px;

      .select__item {
        cursor: pointer;
        transition: 0.2s ease;
        display: flex;
        justify-content: space-between;

        &:hover {
          &::after {
            content: url("../images/check.svg");
          }
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 72px;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding: 3rem 0 3rem 1rem;
    background-color: #fafcff;
    z-index: 999;

    .menu__list {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
      padding-left: 0;

      .menu__item {
        color: #0b1f33;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;

        a {
          text-decoration: none;
          color: #0b1f33;
          font-weight: 600;
        }

        &:hover {
          color: #489fe1;
        }
      }
    }

    .menu__select {
      border: 1px solid #f2f4f7;
      width: 8rem;
      height: 8.5rem;
      border-radius: 8px;
      box-shadow: 0px 4px 6px -2px #10182808;
      box-shadow: 0px 12px 16px -4px #10182814;
      background-color: #ffffff;
      font-size: 1rem;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 10px 14px;

      .select__item {
        cursor: pointer;
        transition: 0.2s ease;
        display: flex;
        justify-content: space-between;

        &:hover {
          &::after {
            content: url("../images/check.svg");
          }
        }
      }
    }

    @media screen and (max-width: 425px) {
      height: 100vh;
      width: 100vw;
      z-index: 999;
    }
    @media screen and (max-width: 375px) {
      height: 100vh;
      width: 100vw;
      z-index: 999;
    }
  }
}

.active {
  color: #000;
  font-weight: 600;
}
