.createPetition {
    font-size: 1.125rem;
    background-color: #FAFCFF;

    .createPetition__content {
        padding-top: 7rem;
        padding-bottom: 16rem;

        @media screen and (max-width: 768px) {
            padding-top: 5rem;
            padding-bottom: 8rem;
        }

        h1 {
            font-size: 1.5rem;
            margin-bottom: 2.5rem;
        }

        .createPetition__title {
            margin-bottom: 2.5rem;

            h3 {
                font-size: 1.125rem;
                font-weight: 600;
                margin-bottom: 0.5rem;
            }

            p {
                color: #667085;
                font-size: 1.125rem;
            }

            .errorTitle {
                border: 1px solid #FDA29B;

                &::placeholder {
                    color: #FDA29B;
                }
            }

            input {
                width: 70vw;
                font-size: 1.125rem;
                border: 1px solid #DDDEE2;
                padding: 1rem;
                border-radius: 8px;
                @media screen and (max-width: 768px) {
                    width: 90vw;
                }
                @media screen and (max-width: 425px) {
                    width: 85vw;
                }
            }
        }

        .createPetition__desc {
            margin-bottom: 2.5rem;

            h3 {
                font-size: 1.125rem;
            }

            .createPetition__text {
                color: #667085;
                font-size: 1.125rem;
            }

                .custom-quill-editor{
                    height: 25rem;
                    width: 72vw;
                    background-color:#fff;
                    color: black;
                    font-size: 18px;
                    border: 1px solid #DDDEE2;
                    @media screen and (max-width: 768px) {
                        width: 92vw;
                    }
                }
                .errorDesc{
                    height: 25rem;
                    background-color:#fff;
                    color: black;
                    font-size: 18px;
                    border: 2px solid #FDA29B;
                    &::placeholder {
                        color: #FDA29B;
                    }
            }
        }

        .createPetition__image {
            margin-top: 7rem;

            h3 {
                font-size: 1.125rem;
                font-weight: 600;
                margin-bottom: 0.5rem;
            }

            p {
                color: #667085;
                font-size: 1.125rem;
            }

            .createPetition__newImg {
                display: flex;
                justify-content: space-around;
                width: 50vw;
                margin-bottom: 7.4rem;
                border: 1px solid #4D83FA;
                border-radius: 8px;
                padding-bottom: 1rem;
                @media screen and (max-width: 700px) {
                    width: 60vw;
                }
                @media screen and (max-width: 500px) {
                    width: 80vw;
                }

                .uploadImage {
                    border-radius: 50%;
                    padding: 1rem;
                }

                p {
                    margin-bottom: 0;
                    padding: 0;
                }
                .progressImg{
                    width: 100%;
                }
            }

            .createPetition__box {
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid #EAECF0;
                width: 50vw;
                height: 10.8rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2.5rem 1.5rem;
                margin-bottom: 7.4rem;
                @media screen and (max-width: 700px) {
                    width: 60vw;
                }
                @media screen and (max-width: 500px) {
                    width: 80vw;
                }

                img {
                    margin-bottom: 0.75rem;
                }

                p {
                    margin-bottom: 0;

                    span {
                        color: #3695D8;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
            .errorImg{
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid #FDA29B;
                width: 37rem;
                height: 10.8rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2.5rem 1.5rem;
                margin-bottom: 7.4rem;
                img {
                    margin-bottom: 0.75rem;
                }

                p {
                    margin-bottom: 0;

                    span {
                        color: #3695D8;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
        }

        .createPetition__buttons {
            display: flex;
            gap: 1rem;

            .buttons__overview {
                color: #3695D8;
                border: 1px solid #3695D8;
                border-radius: 8px;
                padding: 0.6rem 2.9rem;
                background-color: #fff;
                font-weight: 600;
                font-size: 1.125rem;
                cursor: pointer;
            }

            .buttons__publish {
                background-color: #3695D8;
                color: #fff;
                font-weight: 600;
                border: 1px solid #3695D8;
                border-radius: 8px;
                padding: 0.6rem 0.7rem;
                font-size: 1.125rem;
                cursor: pointer;
            }
        }
    }
}