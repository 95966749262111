.verifyPage__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    .verifyPage__modal-content {
        width: 25rem;
        height: 15rem;
        background-color: #fff;
        box-shadow: 0px 8px 8px -4px #1018280A;
        box-shadow: 0px 20px 24px -4px #1018281A;
        border-radius: 12px;
        padding: 1.5rem;
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .verifyPage__modal-btn {
        background-color: #489FE1;
        color: #fff;
        border-radius: 8px;
        width: 10rem;
        height: 2.75rem;
        border: none;
        margin-bottom: 12px;
        cursor: pointer;
        transition: 0.3s ease;
        font-weight: 700;

        &:hover {
            background-color: #3580b9;
        }
    }
}