.review-section {
    display: flex;
    flex-direction: column;
  
    h3 {
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      color: rgb(62, 50, 50);
  
      span {
        width: 4px;
        height: 10px;
        border-radius: 12px;
        background: rgb(57, 152, 232);
      }
    }
  
    &__auth-warning,
    &__warning {
      height: 150px;
      margin-bottom: 70px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
  
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
  
      border: 1px solid #c5c6c5;
      border-radius: 10px;
  
      button {
        text-decoration: underline;
        color: #0077b6;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  
    &__loader {
      display: flex;
      justify-content: center;
    }
  
    form {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      textarea {
        height: 100px;
        width: 100%;
        padding: 26px 18px;
        border: 1px solid rgb(197, 198, 197);
        border-radius: 12px;
        font-size: 18px;
      }
  
      button {
        align-self: flex-end;
        padding: 10px 16px;
        border-radius: 12px;
        background: rgb(57, 152, 232);
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        border: none;
        cursor: pointer;
      }
    }
  
    &__list {
      ul {
        display: flex;
        flex-direction: column;
        gap: 30px;
        list-style-type: none;
      }
  
      .review {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid rgb(197, 198, 197);
        border-radius: 12px;
  
        &__author {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        &__header {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
  
        &__author-name {
          font-size: 16px;
          font-weight: 800;
          line-height: 19px;
          color: rgb(62, 50, 50);
        }
  
        &__date {
          display: flex;
          align-items: center;
          gap: 6px;
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
  
            color: rgba(62, 50, 50, 0.75);
          }
        }
  
        &__description {
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .review-section {
      h3 {
        margin-bottom: 30px;
      }
  
      form {
        margin-bottom: 56px;
      }
  
      &__list {
        ul {
          display: flex;
          flex-direction: column;
          gap: 25px;
        }
  
        .review {
          padding: 15px 20px;
  
          &__description {
            font-size: 16px;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 550px) {
    .review-section {
      h3 {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 24px;
      }
  
      form {
        margin-bottom: 50px;
        gap: 12px;
  
        textarea {
          height: 130px;
        }
      }
  
      &__list {
        ul {
          gap: 13px;
        }
  
        .review {
          padding: 15px;
  
          &__description {
            font-size: 16px;
          }
        }
      }
    }
  }
  