.forget {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 23rem;
    align-items: center;
    @media screen and (max-width:768px) {
        padding-top: 6.6rem;
        padding-bottom: 20rem;
        width: 22.5rem;
    }
    @media screen and (max-width:375px) {
        padding-bottom: 17rem;
        width: 20rem;
    }
    @media screen and (max-width:320px) {
        width: 18rem;  
    }
    .forget__img {
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
    }

    .forget__title {
        font-size: 2rem;
        line-height: 2.3rem;
        color: #101828;
        font-weight: 700;
        margin-bottom: 0;
    }

    .forget__info {
        color: #667085;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .forget__code {
        color: #344054;
        font-size: 1rem;
        align-self: flex-start;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .forget__input {
        width: 28vw;
        border: 1px solid#D0D5DD;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 10px 14px;
        
        &:focus{
            border: 2px solid#489FE1;
        }
        @media screen and (max-width:768px) {
            width: 21rem;  
        }
        @media screen and (max-width:375px) {
            width: 19rem;  
        }
        @media screen and (max-width:320px) {
            width: 17.5rem;  
        }
    }

    .forget__btnActive {
        cursor: pointer;
        background-color: #489FE1;
        color: #fff;
        border: none;
        margin-bottom: 2rem;
        width: 30vw;
        height: 2.75rem;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1rem;
        margin-top: 2rem;
        @media screen and (max-width:768px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

    }
    .disabled{
        cursor: pointer;
        background-color: #9EA7AF;
        color: #fff;
        border: none;
        margin-bottom: 2rem;
        width: 30vw;
        height: 2.75rem;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1rem;
    }
    .forget__error-text{
        color: #F04438;
        font-size: 14px;
        font-weight: 600;
        align-self: flex-start;
        margin-top: 0.5rem;
    }
}