.confirm{
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 23rem;
    align-items: center;

    @media screen and (max-width:768px) {
        padding-top: 6.6rem;
        padding-bottom: 11rem;
        width: 22.5rem;
    }
    @media screen and (max-width:425px) {
        padding-bottom: 6rem;
        width: 20rem;
    }
    @media screen and (max-width:320px) {
        width: 19rem;
    }

    img{
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
    }
    h2{
        font-size: 2rem;
        line-height: 2.3rem;
        color: #101828;
        font-weight: 500;
    }
    p{
        color:#667085;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin-bottom: 3.75rem;
    }
    .confirm__code{
        color: #344054;
        font-size: 1rem;
        align-self: flex-start;
        font-weight: 500;
        margin-bottom: 5px;
    }
    .confirm-inputs{
        display: flex;
        gap: 0.5rem;
        @media screen and (max-width:768px) {
            gap: 6px;
        }
        @media screen and (max-width:425px) {
            gap: 3px;
        }
    }
    input{
        align-self: flex-start;
        height: 4rem;
        width: 4rem;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        font-size: 3rem;
        text-align: center;
        margin-bottom: 2rem;
        color: #489FE1;
        @media screen and (max-width:768px) {
            width: 3.4rem;
            height: 4rem;
        }
        @media screen and (max-width:425px) {
            width: 3rem;
            height: 3.5rem;
        }
        @media screen and (max-width:355px) {
            width: 2.9rem;
            height: 3.5rem;
        }
    }
    .confirm__btn{
        color: #489FE1;
        cursor: pointer;
    }
    button{
        width: 30vw;
        padding: 0.625rem 0;
        cursor: pointer;
        border-radius: 8px;
    }
    .confirmBtn{
        background-color: #489FE1;
        color: #fff;
        border: none;
        margin-bottom: 1rem;
        width: 100%;
        @media screen and (max-width:768px) {
            width: 22.5rem;
        }
        @media screen and (max-width:425px) {
            width: 20rem;
        }
        @media screen and (max-width:320px) {
            width: 19rem;
        }
    }
    .cancelBtn{
        background-color: #fff;
        border: 1px solid #D0D5DD;
        color: #344054;
        @media screen and (max-width:768px) {
            width: 22.5rem;
        }
        @media screen and (max-width:425px) {
            width: 20rem;
        }
        @media screen and (max-width:320px) {
            width: 19rem;
        }
    }
}