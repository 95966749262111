.error{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    .error__title{
        font-size: 2.25rem;
        font-weight: 400;
        color: #0B1F33;
    }
    .error__btn{
        background-color: #3695D8;
        width: 20rem;
        height: 3.4rem;
        color: #fff;
        border: none;
        border-radius: 13px;
        cursor: pointer;
        font-size: 18px;
    }
}