.editPetition{
    font-size: 1.125rem;
    background-color: #FAFCFF;

    .editPetition__content{
        padding-top: 7rem;
        padding-bottom: 16rem;
    
    h1{
        font-size: 1.5rem;
        margin-bottom: 2.5rem;
    }

    .editPetition__title{
        margin-bottom:2.5rem;
        h3{
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        p{
            color: #667085;
            font-size: 1.125rem;
        }
        .error {
            border: 1px solid #FDA29B;
    
            &::placeholder {
              color: #FDA29B;
            }
          }
        input{
            width: 60rem;
            font-size: 1.125rem;
            border: 1px solid #DDDEE2;
            padding: 1rem;
            border-radius: 8px;
            
        }
    }

    .editPetition__desc{
        margin-bottom: 2.5rem;
        h3{
            font-size: 1.125rem;
        }
        p{
            color: #667085;
            font-size: 1.125rem;
        }
        .editPetition__container{
            width: 62.3rem;
            height: 27.5rem;
            
            
            .editPetition__fonts{
                background-color: #E3EBFC;
                border-radius: 8px 8px 0 0;
            }
            .error {
                border: 1px solid #FDA29B;
      
                &::placeholder {
                  color: #FDA29B;
                }
              }
            textarea{
                width: 61rem;
                height: 25rem;
                font-size: 1.125rem;
                padding-left: 1rem;
                border: 1px solid #DDDEE2;
            }

        }
    }

    .editPetition__image{
        h3{
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 0.5rem; 
        }
        p{
            color: #667085;
            font-size: 1.125rem;
        }
        .editPetition__newImg{
            display: flex;
            justify-content: space-around;
            width: 37.5rem;
            margin-bottom: 7.4rem;
            border: 1px solid #4D83FA;
            border-radius: 8px;
            padding-bottom: 1rem;
            .uploadImage{
                border-radius: 50%;
                padding: 1rem;
            }
            p{
                margin-bottom: 0;
                padding: 0;
            }
        }
        .editPetition__box{
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid #EAECF0;
            width: 37rem;
            height: 10.8rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2.5rem 1.5rem;
            margin-bottom: 7.4rem;

            img{
                margin-bottom: 0.75rem;
            }
            p{
                margin-bottom: 0;
                span{
                    color: #3695D8;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }
    }

    .editPetition__buttons{
        display: flex;
        gap: 1rem;

        .buttons__overview{
            color: #3695D8;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 2.9rem;
            background-color: #fff;
            font-weight: 600;
            font-size: 1.125rem;
            cursor: pointer;
        }
        .buttons__publish{
            background-color: #3695D8;
            color: #fff;
            font-weight: 600;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 0.7rem;
            font-size: 1.125rem;
            cursor: pointer;
        }
    }
    }
}