.analytics{
    background-color: #FAFCFF;

    .analytics__content{
        padding-top: 1.8rem;
        max-width: 45rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        padding-bottom: 8.9rem;
    }
    .analytics__block{
        display: flex;
        gap: 1.8rem;
        @media screen and (max-width: 670px) {
            flex-wrap: wrap;
        }
        
        .analytics__item{
            font-size: 0.9rem;
            color:#667085;
            font-weight: 500;
            width: 20.8rem;
            height: 11rem;
            background-color: #fff;
            border: 1px solid#EAECF0;
            border-radius: 8px;
            padding: 1.5rem;

            @media screen and (max-width: 825px) {
                width: 18rem;
            }
            @media screen and (max-width: 768px) {
                width: 16rem;
            }
            @media screen and (max-width: 670px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .analytics__title{
                font-size: 1rem;
                font-weight: 600;
                color: #0B1F33;
            }
            .analytics__count{
                font-weight: 600;
                font-size: 2.25rem;
                color: #0B1F33;
            }
        }
    }
    .analytics__bar{
        @media screen and (max-width: 425px) {
            height: 35rem;
        }
    }
}