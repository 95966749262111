.preview{
    padding-top: 6.9rem;
    background-color: #FAFCFF;
    h1{
        text-align: center;
        margin-bottom: 5.3rem;
    }
    .preview__images{
        display: flex;
        justify-content: space-between;

        .preview__petition-img{
            width: 60vw;
            height: 32rem;
            object-fit: cover;
            border-radius: 15px;
            margin-bottom: 2.8rem;
        }
        .preview__votes{
            width: 21rem;
            height: 7rem;
            padding: 1.25rem;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            box-shadow: 0px 1px 4px 0px #E3EBFC;
            border-radius: 15px;

            .preview__progressBar-container{
                width: 21.5rem;
                height: 1.5rem;
                background-color: #F2F4F7;
                border-radius: 4px;
                .preview__progressBar{
                    height: 100%;
                    width: 0;
                    background-color: #007bff;
                    transition: width 0.3s ease;
                    .preview__progress-bar-fill{
                        height: 100%;
                    }
                }
            }

            .votes__count{
                display: flex;
                justify-content: space-between;

                .votes__res{
                    color:#3695D8;
                    font-weight: 700;
                    font-size: 1.5rem
                }
                .votes__total{
                    font-weight: 700;
                    font-size: 1.5rem
                }
            }
        }
    }
    .preview__info{
        color:#666666;
        margin-bottom: 3.5rem;
        .preview__calendar{
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
    .preview__petition{
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 1px 4px 0px #E3EBFC;
        padding: 2rem;
        margin-bottom: 6.6rem;
    }
    .preview__buttons{
        display: flex;
        gap: 1rem;
        padding-bottom: 11.3rem;

        .buttons__edit{
            color: #3695D8;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 2.9rem;
            background-color: #fff;
            font-weight: 600;
            font-size: 1.125rem;
            cursor: pointer;
        }
        .buttons__publish{
            background-color: #3695D8;
            color: #fff;
            font-weight: 600;
            border: 1px solid #3695D8;
            border-radius: 8px;
            padding: 0.6rem 0.7rem;
            font-size: 1.125rem;
            cursor: pointer;
        }
    }
}

