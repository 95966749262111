.about{
    margin-top: 6.25rem;
    font-size: 1.125rem;

    @media screen and (max-width: 768px) {
        margin-top: 5rem;
    }
    .about__image{
        img{
            width: 90vw;
            margin-bottom: 4.3rem;
        }
    }
    .about__info{
        display: flex;
        flex-direction: column;
        gap: 3rem;

        h3{
            font-size: 1.875rem;
            font-weight: 600;
            line-height: 2.3rem;
        }
    }
    &__questions{
        margin: 7.2rem 0;
        h3{
            font-size: 1.875rem;
            font-weight: 600;
            line-height: 2.3rem;
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }

        .about__blocks{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .about__block{
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
                .about__item{
                    box-shadow: 0px 1px 4px 0px #E3EBFC;
                    padding: 1.75rem 1.5rem;
                    border-radius: 15px;
                    width: 40vw;

                    @media screen and (max-width: 768px) {
                        width: 80vw;
                    }
                    @media screen and (max-width: 425px) {
                        align-items: center;
                        padding: 1rem;
                    }
                    details{
                        summary{
                            display: flex;
                            align-items: center;
                            img{
                                margin-left: 5rem;
                                @media screen and (max-width: 425px) {
                                    margin-left: 1rem;
                                }
                            }
                        }
                        p{
                            width: 487px;
                            height: 122px;
                        }
                    }
                }
            }   
        }
    }
    
}