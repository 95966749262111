.verify{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &__title{
    }

    &__passport, &__face{
        width: 40vw;
        height: 17rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        border: 1px solid #4D83FA;
        border-radius: 8px;
    }

    .passport{

        &__link{
            color: #4D83FA;
            cursor: pointer;
            text-decoration: underline;
        }

        &__img{
            width: 17rem;
            height: 12rem;
            object-fit: cover;
        }

        &__idIcon{
            width: 15rem;
            height: 11rem;
            object-fit: cover;
        }
    }

    .face{

        &__link{
            color: #4D83FA;
            cursor: pointer;
            text-decoration: underline;
        }

        &__img{
            width: 17rem;
            height: 12rem;
            object-fit: cover;
        }

        &__icon{
            width: 15rem;
            height: 11rem;
            object-fit: cover;
        }
    }

    &__btn{
        background-color: #3695D8;
        color: #fff;
        font-weight: 600;
        border: 1px solid #3695D8;
        border-radius: 8px;
        padding: 0.6rem 0.7rem;
        font-size: 1.125rem;
        cursor: pointer;
        transition: 0.3s ease-in;

        &:hover{
            background-color: #2778b2;
        }
    }
}