@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  background-color: #FAFCFF;
  height: 100%;
}
.container{
  width: 90vw;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .container{
    width: 95vw;
  }
}
.loadingContainer{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}